// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR');
  });
}

// CSS
import '../css/index.css';

// Stimulus Controllers
import './controllers';

// Vendor
import 'lazysizes';

// Block CSS Transitions
// On Load
document.addEventListener('DOMContentLoaded', () => {
  const node = document.querySelector('.no-transitions');
  node.classList.remove('no-transitions');
});
