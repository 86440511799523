import { Controller } from '@hotwired/stimulus';
import { useMatchMedia } from 'stimulus-use';

export default class extends Controller {
  initialize() {}

  connect() {
    useMatchMedia(this, {
      mediaQueries: {
        tabletLayout: '(min-width: 768px)',
      },
    });
  }

  static targets = ['content', 'icon', 'container'];

  static values = {
    isOpen: Boolean,
    currentItem: String,
  };

  toggle() {
    this.isOpenValue = !this.isOpenValue;
    this.toggleContent();
    this.toggleContainer();
    this.toggleIcon();
  }

  toggleContent() {
    this.contentTarget.setAttribute(
      'aria-expanded',
      this.isOpenValue
    );
    this.contentTarget.classList.toggle('h-auto', this.isOpenValue);
    this.contentTarget.classList.toggle('visible', this.isOpenValue);
    this.contentTarget.classList.toggle('h-0', !this.isOpenValue);
    this.contentTarget.classList.toggle(
      'invisible',
      !this.isOpenValue
    );
  }

  toggleContainer() {
    this.containerTarget.classList.toggle('active');
  }

  toggleIcon() {
    this.iconTarget.children[0].classList.toggle(
      'rotate-180',
      this.isOpenValue
    );
  }

  close() {
    this.isOpenValue = false;
    this.contentTarget.setAttribute(
      'aria-expanded',
      this.isOpenValue
    );
    this.contentTarget.classList.remove('h-auto');
    this.contentTarget.classList.add('h-0');
    this.contentTarget.classList.remove('visible');
    this.contentTarget.classList.add('invisible');
    this.containerTarget.classList.remove('active');
    this.iconTarget.children[0].classList.remove('rotate-180');
    this.iconTarget.classList.remove('shadow-none');
  }

  tabletLayoutChanged({ matches }) {
    this.isOpenValue = matches ? true : false;
    if (!this.draweractiveValue) {
      this.close();
    }
    this.contentTarget.setAttribute(
      'aria-expanded',
      this.isOpenValue
    );
  }
  isTabletLayout({ matches }) {
    this.isOpenValue = true;
    const options = this.contentTarget.querySelectorAll('ul > li');
    options.forEach((option) => {
      option.classList.remove('hidden');
    });
    this.contentTarget.setAttribute(
      'aria-expanded',
      this.isOpenValue
    );
  }
}
