import { ApplicationController } from 'stimulus-use';

export default class extends ApplicationController {
  initialize() {
    this.handler = this.handler.bind(this);
  }

  static targets = ['navItem', 'anchorSection'];

  connect() {
    this.observer = new IntersectionObserver(this.handler, {
      rootMargin: '-40% 0% -40% 0%',
      threshold: 0,
    });
    this.anchorSectionTargets.forEach((item) => {
      this.observer.observe(item);
    });
  }

  handler(entries) {
    entries.forEach((entry) => {
      entry.isIntersecting
        ? this.isInView(entry)
        : this.isOutOfView(entry);
    });
  }

  getNavItem(id) {
    const navItem = this.navItemTargets.find(
      (x) => x.href.split('#').pop() === id
    );
    return navItem;
  }

  setNavItemActive(navItem) {
    navItem.classList.add('text-black', 'animate-bounceOnce');
  }

  unsetNavItem(el) {
    el.classList.remove('text-black');
    setTimeout(() => {
      el.classList.remove('animate-bounceOnce');
    }, 1000);
  }

  isInView(entry) {
    this.currentItem != undefined &&
      this.unsetNavItem(this.currentItem);
    this.currentItem = this.getNavItem(entry.target.id);
    this.setNavItemActive(this.currentItem);
  }

  isOutOfView(entry) {
    this.currentItem != undefined &&
      this.unsetNavItem(this.getNavItem(entry.target.id));
  }
}
