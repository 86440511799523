import { ApplicationController } from 'stimulus-use';

export default class extends ApplicationController {
  initialize() {
    this.handler = this.handler.bind(this);
  }

  static targets = ['item'];

  connect() {
    this.observer = new IntersectionObserver(this.handler, {
      rootMargin: '-5% 0% -5% 0%',
      threshold: 0,
    });
    this.itemTargets.forEach((item) => {
      this.observer.observe(item);
      this.initItem(item);
    });
  }

  initItem(item) {
    switch (item.dataset.type) {
      case 'cardList':
        Array.from(item.children).forEach((element) => {
          element.classList.add(
            'transition-transform',
            'duration-500',
            'translate-y-12'
          );
        });
        break;
      case 'textList':
        Array.from(item.children).forEach((element) => {
          element.classList.add(
            'transition-opacity',
            'duration-500',
            'opacity-0'
          );
        });
        break;
      case 'slideFromLeft':
        Array.from(item.children).forEach((element) => {
          element.classList.add(
            'transition',
            '-translate-x-full',
            'opacity-0',
            'duration-700'
          );
        });
        break;
    }
  }

  handler(entries) {
    entries.forEach((entry) => {
      entry.isIntersecting && this.isInView(entry);
    });
  }

  isInView(entry) {
    this.animateItem(entry.target);
    this.observer.unobserve(entry.target);
  }

  animateItem(item) {
    switch (item.dataset.type) {
      case 'cardList':
        this.cardListSlide(item);
        break;
      case 'textList':
        this.textListSlide(item);
        break;
      case 'slideFromLeft':
        this.slideFromLeft(item);
        break;
    }
  }

  slideFromLeft(item) {
    const children = item.children;
    Array.from(children).forEach((item, index) => {
      setTimeout(() => {
        item.classList.remove('-translate-x-full');
        item.classList.remove('opacity-0');
      }, index * 60);
    });
  }
  textListSlide(item) {
    const children = item.children;
    Array.from(children).forEach((item, index) => {
      setTimeout(() => {
        item.classList.remove('opacity-0');
      }, index * 60);
    });
  }
  cardListSlide(item) {
    const children = item.children;
    Array.from(children).forEach((item, index) => {
      setTimeout(() => {
        item.classList.remove('translate-y-12');
      }, index * 60);
    });
  }
}
